import React, { useState } from 'react'
import { Button, TextInput } from '@mantine/core'
import { navigate } from 'gatsby'
import qs from 'qs'
import generateIdeasButtonIcon from '@assets/images/free-ai-tools/generateIdeasButtonIcon.svg'
import {
  ForItemsWrapper,
  TextInputWrapper,
  ButtonWrapper,
  ButtonIcon,
} from '@components/pages/free-ai-tools/FreeAiToolsFormElements'
import { apiPost } from '../../../../api/api'
import _isEmpty from 'lodash/isEmpty'
import _trim from 'lodash/trim'
import ReCaptcha from '@pittica/gatsby-plugin-recaptcha'

const BusinessPlanGeneratorForm = ({ intl }) => {
  const [description, setDescription] = useState('')
  const [audience, setAudience] = useState('')
  const [stage, setStage] = useState('')
  const [location, setLocation] = useState('')
  const [funding, setFunding] = useState('')

  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const icon = <ButtonIcon src={generateIdeasButtonIcon} />

  const isGenerateButtonDisabled = _isEmpty(_trim(description))

  const onSubmit = async (token) => {
    const params = qs.stringify(
      {
        description,
        audience,
        stage,
        location,
        funding,
      },
      { encodeValuesOnly: true },
    )

    try {
      setLoading(true)
      await apiPost.verifyRecaptcha(token)
      const isDefaultLocale = intl.locale === intl.defaultLocale
      const navigateLink = isDefaultLocale
        ? `/free-ai-tools/business-plan-generator/output?${params}`
        : `/${intl.locale}/free-ai-tools/business-plan-generator/output?${params}`

      await navigate(navigateLink)
    } catch (e) {
      setLoading(false)
      setSubmitted(false)
    }
  }

  return (
    <>
      <ReCaptcha
        action="businessIdeaGeneratorForm"
        siteKey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
        onVerify={(token) => onSubmit(token)}
        submitted={submitted}
      />

      <ForItemsWrapper>
        <TextInputWrapper>
          <TextInput
            size="md"
            radius="md"
            withAsterisk
            label={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.question01' })}
            placeholder={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.placeholder01' })}
            value={description}
            onChange={(event) => setDescription(event.currentTarget.value)}
          />
        </TextInputWrapper>

        <TextInputWrapper>
          <TextInput
            size="md"
            radius="md"
            label={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.question02' })}
            placeholder={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.placeholder02' })}
            value={audience}
            onChange={(event) => setAudience(event.currentTarget.value)}
          />
        </TextInputWrapper>

        <TextInputWrapper>
          <TextInput
            size="md"
            radius="md"
            label={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.question03' })}
            placeholder={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.placeholder03' })}
            value={stage}
            onChange={(event) => setStage(event.currentTarget.value)}
          />
        </TextInputWrapper>

        <TextInputWrapper>
          <TextInput
            size="md"
            radius="md"
            label={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.question04' })}
            placeholder={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.placeholder04' })}
            value={location}
            onChange={(event) => setLocation(event.currentTarget.value)}
          />
        </TextInputWrapper>

        <TextInputWrapper>
          <TextInput
            size="md"
            radius="md"
            label={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.question05' })}
            placeholder={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.placeholder05' })}
            value={funding}
            onChange={(event) => setFunding(event.currentTarget.value)}
          />
        </TextInputWrapper>
      </ForItemsWrapper>

      <ButtonWrapper>
        <Button
          fullWidth
          size="md"
          radius="md"
          type="submit"
          variant="filled"
          color="yellow"
          leftSection={icon}
          loading={loading}
          disabled={isGenerateButtonDisabled}
          onClick={() => {
            setLoading(true)
            setSubmitted(true)
          }}
        >
          {intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.sectionCTA.buttonText' })}
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default BusinessPlanGeneratorForm
