import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import FreeAiToolsFormWrapper from '@components/pages/free-ai-tools/FreeAiToolsFormWrapper'
import BusinessPlanGeneratorForm from '@components/pages/free-ai-tools/forms/BusinessPlanGeneratorForm'

const BusinessPlanGeneratorFormSection = ({ intl }) => {
  return (
    <FreeAiToolsFormWrapper
      title={intl.formatMessage({ id: 'free-ai-tools-pages.business-plan-generator.form.title' })}
    >
      <BusinessPlanGeneratorForm intl={intl} />
    </FreeAiToolsFormWrapper>
  )
}

export default injectIntl(BusinessPlanGeneratorFormSection)
